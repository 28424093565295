// styles for the copy selection & confirmation popup controls

.copy-modal__selection-popup {
  @include popup-shadow;
  width: 35rem;
  background-color: $color-grey-light;

  border-radius: 0.5rem;

  color: $color-grey-dark;

  &--force-top {
    position: absolute;
    top: 0;
  }

  .popup-header {
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem;
  }

  .copy-modal__selection-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    .copy-modal__selection-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 1rem;
      font-size: $font-size-small;

      button {
        margin: 0 1rem;
      }

      button.copy-modal__selection-confirm {
        background-color: $color-green-mid-dark;

        &:hover {
          background-color: $color-green;
        }
      }
    }
    .copy-modal__matter-type {
      width: 100%;
      margin: 0.5rem;
      display: flex;
      justify-items: center;
      align-items: center;

      &--dropdown {
        flex-grow: 1;
        width: 100%;
        background-color: $color-white;
        padding: 0.5rem;
        padding-left: 1rem;
        border-radius: 0.5rem;
      }
    }

    .copy-modal__checkbox-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    .copy-modal__checkbox-item {
      display: flex;
      align-items: center;
      padding: 1rem;
      font-size: $font-size-small;
    }
    .copy-modal__checkbox-text {
      padding-left: 1rem;
    }
  }
}
