// Styles for the toast informational/error transient popup

.toast-list {
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;

  display: flex;
  flex-flow: column nowrap;

  .toast {
    display: flex;
    flex-flow: column nowrap;

    margin-top: 1rem;
    //margin-right: 1rem;

    opacity: 1;

    transition: 0.9s all;

    &--error {
      @include error-box;
    }

    &--warning {
      background-color: $color-yellow-lightest;
      color: $color-yellow-darkest;
      font-weight: bold;
    }

    &--info {
      background-color: $color-green-lightest;
      color: $color-green-darkest;
    }

    &--hidden {
      opacity: 0;
    }

    .toast__header {
      text-align: right;
      padding: 5px 5px 0 0;
    }

    .toast__body {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      padding: 1rem;
      padding-top: 0;

      //height: 5rem;
      width: 20rem;

      text-align: center;
    }
  }
}
