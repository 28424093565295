.record__overview-panel {
  width: 25rem;
  margin: 1rem 0 1rem 1rem;
  height: calc(100% - 2rem);
  display: flex;
  flex-flow: column nowrap;

  position: relative;

  background-color: $color-white; //$color-grey-lightest;
  border-radius: 1rem;

  padding-bottom: 0.5rem;
  margin-bottom: 1rem;

  color: $color-grey-dark;

  &--collapsed {
    width: 1.5rem;
    margin-left: -0.5rem;
  }

  .overview-panel__expander {
    position: absolute;
    top: 1.2rem;
    right: -0.5rem;
    font-size: $font-size-large;
    color: $color-grey-mid;

    &:hover {
      color: $color-grey-light;
      cursor: pointer;
    }
  }

  .overview-panel__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid $color-grey-mid;

    background-color: $color-grey-lightest;
    border-radius: 1rem 1rem 0 0;

    font-size: $font-size-small;
    font-weight: bold;
  }

  .overview-panel__icon {
    display: flex;
    align-items: center;

    font-size: $font-size-x-large;
    color: $color-bg-primary;
    margin-right: 0.5rem;
  }
  .overview__copy-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    &-text {
      font-size: $font-size-xxx-large;
      font-weight: bold;
      color: $color-red;
    }
  }

  .overview__summary-block {
    flex-shrink: 0;

    display: grid;
    grid-template-columns: 1fr 1fr;

    padding: 1rem;

    .overview__summary-label {
      //font-weight: bold;
      text-align: right;
      margin-right: 1rem;
    }

    .overview__summary-data {
    }
  }

  .overview__section-list {
    flex-grow: 1;
    min-height: 2rem;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 0.5rem;
    //height: calc(100vh - 25.5rem);

    .overview__section {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;

      padding: 1rem;
      margin: 0.5rem 1rem;

      background-color: $color-grey-lightest;
      border: 1px solid $color-grey-mid;
      border-radius: 0.5rem 0.5rem 0 0;

      &:last-of-type {
        border-radius: 0.5rem;
      }

      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        filter: brightness(110%);
      }

      &--warning {
        background-color: $color-yellow-lightest;
        color: $color-yellow-darkest;
        border-color: $color-yellow-darkest;
      }

      &--error {
        @include error-box;

        // .overview__section-title {
        //   font-weight: bold;
        // }
      }

      .overview__section-title {
        //        font-weight: bold;
        font-size: $font-size-medium;
      }

      .overview__section-message {
        font-size: $font-size-small;
      }
    }
  }

  .overview-panel__field-search {
    padding-bottom: 1rem;

    .field-search__container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      margin: 1rem 1rem 0 1rem;
      //margin-bottom: 2rem;
      //height: 5rem;

      background-color: $color-white;

      .field-search__icon {
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
      }

      input {
        width: 100%;
      }
    }
  }
}
