.paginator {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;

  width: 10rem;

  .paginator__page-action {
    display: flex;
    align-items: center;

    width: 2rem;
    cursor: pointer;

    font-size: 3rem;
  }

  .paginator__page-no-action {
    min-width: 4rem;
  }

  .paginator__page-number {
    margin-right: 0.5rem;
  }
}
