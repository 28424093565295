.header-bar {
  background-color: $color-grey-lighter;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: $color-text-primary;
}

.switch-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .react-switch-label {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-left: 0.5rem;
    width: 2.5rem;
    height: 1.5rem;

    background: $color-grey-dark;
    border-radius: 100px;

    cursor: pointer;

    &--on {
      background-color: $color-bg-button;
    }

    &--off {
      background-color: $color-grey-mid;
    }

    &:active {
      background-color: $color-grey-light;
    }
  }

  .react-switch-label .react-switch-button {
    content: "";
    position: absolute;
    top: 0.25rem;
    left: 0.75rem;
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

    transition: background-color 0.2s;
  }

  .react-switch-label--on .react-switch-button--on {
    left: 1.3rem;
  }

  .react-switch-label--off .react-switch-button--off {
    left: 0.25rem;
  }

  .react-switch-button {
    width: 2.5rem;
  }
}
