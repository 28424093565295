.record__data-panel-list {
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;

  .data-panel-list__outer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    flex-grow: 0;
    width: 100%;
    //height: calc(100vh - 22rem);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    padding-right: 1rem;

    // &--multiple-in-row {
    //   height: calc(100vh - 26rem);
    // }

    //background-color: $color-grey-mid;

    .data-panel-list__data-panel {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;

      margin-top: 1rem;

      width: 100%;

      .data-panel__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 1rem;

        &--expanded {
          border-radius: 1rem 1rem 0 0;
        }

        background-color: $color-bg-primary;
        color: $color-white;

        padding: 0.7rem;

        .data-panel__header-lhs {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: center;

          .data-panel__title {
            font-size: $font-size-medium;
          }

          .data-panel__caret {
            font-size: $font-size-x-large;
          }
        }
      }
    }
  }
}
