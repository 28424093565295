.file-uploader {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;

  width: 100%;
  //height: 100%;

  padding: 1rem;

  background-color: $color-grey-lightest;
  border: 1px solid $color-transparent;
  border-radius: 1rem;

  &--is-dragging {
    background-color: $color-blue-lighter;
    border: 1px solid $color-bg-primary;
  }

  &--is-rejected {
    background-color: $color-red-lightest;
    border: 1px solid $color-red-dark;
  }
}
