.option-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  // margin: 0;
  // padding: 0;

  .option-texts {
    display: flex;
    flex-flow: column nowrap;
    //justify-content: center;
    //align-items: center;

    font-weight: normal;

    .option {
      display: flex;
      flex-flow: row nowrap;
      //justify-content: center;
      //align-items: center;

      margin: 0;
      padding: 0;

      &--match {
        padding: 0;
      }
      //margin-right: 1rem;
    }

    .option-matching-matter-types {
      font-size: $font-size-small;
      font-style: italic;

      padding-bottom: 3px;
    }
  }
}
