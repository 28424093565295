// Google Fonts Roboto Import

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Font Variables

$font-primary: "Roboto", sans-serif;
$font-heading: $font-primary;

// Styling of textual elements

html {
  background-color: $color-white;
  color: $color-text-primary;
}

body,
button,
.button,
input,
textarea,
select {
  font-family: $font-primary;
  font-weight: 400;
  font-size: $font-size-medium;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: $font-size-x-large;
}

p {
  font-size: $font-size-medium;
}

textarea {
  font-family: $font-primary;
  font-size: $font-size-medium;
}

a {
  font-weight: bold;
  color: $color-text-secondary;
  text-decoration: underline;

  &:hover {
    color: $color-text-secondary-highlight;
    cursor: pointer;
  }
}

.text-x-small-italic {
  font-size: $font-size-x-small;
  font-style: italic;
}
