// Styles for the Label/Filter Detail View
//.simple-search-filter__popup-container,
.suggested-field__popup-container {
  display: flex;
  flex-flow: column nowrap;

  position: fixed;
  //padding: 1rem;
  background-color: $color-grey-light;
  @include popup-shadow;

  max-height: calc(100vh - 6rem);
  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 0 1rem 1rem 0;

  .popup-header {
    border-radius: 0 1rem 0 0;
    padding: 1rem;
  }

  button {
    margin: 0.5rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
  }
}

.suggested-field__popup {
  width: 35rem;

  //margin: 1rem 0;

  padding: 0.5rem;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  input {
    padding-left: 1rem;
    border-radius: 0.5rem;
  }

  select:focus {
    outline: 1px dotted $color-grey-mid;
  }

  .suggested-field-popup__companies {
    margin-top: 1rem;
  }

  .suggested-field-popup__toolbar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    //margin-bottom: 0.5rem;
    //padding-right: 0.5rem;
    //background-color: $color-grey-lighter;
  }

  .suggested-field-popup__search-options {
    margin: 0 0.5rem;
  }

  .options-list,
  .suggested-field-popup__companies {
    overflow-x: hidden;
    overflow-y: auto;

    max-height: 40rem;
  }

  .suggested-field-popup__operator {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;

    margin: 0 1rem 0.5rem 1rem;
    padding: 0 3px;
    width: 10rem;

    background-color: $color-grey-lighter;

    border-radius: 3px;

    select {
      background-color: $color-grey-lighter;
    }
  }

  select {
    background-color: $color-grey-light;
    color: $color-grey-darker;
    margin-right: 0.5rem;
    font-size: $font-size-medium;
    font-weight: bold;

    //appearance: none;
  }

  p {
    margin: 0 0 0.5rem 1.8rem;
    font-size: $font-size-small;
  }

  input {
    background-color: $color-white;
  }

  .react-datepicker-wrapper {
    margin-bottom: 0.5rem;
  }
}

.popup__field-container {
  display: flex;
  flex-flow: column nowrap;
  background-color: $color-grey-light;
  overflow: hidden;
}

.popup__section-field-container {
  border: 1px solid $color-bg-primary;
  border-radius: 3px;
}

.popup__section-field-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: $color-bg-primary;
}

.popup__section-field-title {
  display: flex;
  flex-flow: row nowrap;
  color: $color-white;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .section-field-title__field-name {
    font-weight: normal;
  }

  .section-field-title__field-values {
    font-weight: bold;
  }
}

.popup__section-field-delete {
  padding: 0 5px;
  font-weight: bold;
  color: $color-red-lighter;

  &:hover {
    cursor: pointer;
    color: $color-red;
  }
}

.popup__section-field-body {
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.simple-search__suggested-field,
.suggested-field__name,
.suggested-field__value,
.option {
  &:hover {
    cursor: pointer;
  }

  &--match {
    color: $color-green-dark;
    font-weight: bold !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &--no-match {
    //color: $color-red-dark;
    //font-weight: bold;
  }
}

.popup__all-operations {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  margin: 0.5rem 0.5rem 0 0.5rem;

  font-size: $font-size-small;
  font-weight: bold;
}

.all-operations__item {
  display: flex;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.popup__fields {
  flex: 1 1 auto;
  min-height: 10rem;
  overflow: auto;
  //padding: 1rem;
}

.popup__footer {
  flex: 0 0 auto;
  min-height: 3rem;
  overflow-y: auto;
  padding: 0.5rem 1rem;
  background-color: $color-grey-mid;
  width: 100%;
}
