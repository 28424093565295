.batch-update__container {
  width: 100%;
}
.batch-update__header {
  background-color: $color-grey-lighter;
  width: 100%;

  padding: 0.5rem;

  font-size: $font-size-large;
  font-weight: bold;
  text-align: center;
}

.batch-update__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.batch-update__main {
  width: calc(100vw - 20rem);
  height: calc(100vh - 11rem);
  overflow-y: auto;
}

.batch-update__summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 20rem;
  height: calc(100vh - 15rem);

  padding: 0 0 1rem 1rem;
  border-left: 1px solid $color-grey-light;

  .summary__records-list {
    padding: 1rem;

    .records-list__intro {
      padding: 0.5rem;
      font-style: italic;
    }
  }
}

.batch-update__table-container {
  margin-bottom: 1rem;
}

.batch-update__table-title {
  padding: 0.5rem;
  font-size: $font-size-large;
  font-weight: bold;
  //text-align: center;

  background-color: $color-blue-light;
  color: $color-white;
}

// .batch-update__table-document-container {
//   width: 100%;
//   overflow-x: scroll;
// }

.batch-update__table {
  tr {
    background-color: $color-grey-lightest;
    &:nth-child(even) {
      background-color: $color-grey-lightest-alt;
    }
    th {
      background-color: $color-grey-mid;
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;

      .td__row-expander {
        text-align: right;
        font-size: $font-size-large;
        cursor: pointer;
        &:hover {
          color: $color-grey-mid;
        }
      }

      .td__field-name {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        font-weight: bold;

        .field-name__matter-type-names {
          font-size: $font-size-small;
          font-style: italic;
          font-weight: normal;
        }
      }

      .td__existing-values {
        max-height: 20rem;
        overflow-y: auto;
      }

      .td__edit-field-container {
        background-color: $color-white;
        border-radius: 0.5rem;
        padding: 0.5rem;

        &--name-search {
          min-width: 40rem;
        }

        &--readonly {
          background-color: $color-grey-lightest;
        }

        input {
          width: 100%;
        }
      }
    }
  }
}

.batch-update__submission {
  height: 80%;
  width: 80%;

  border-radius: 1rem;
  background-color: $color-grey-light;
  @include popup-shadow;

  .submission__intro {
    padding: 1rem;
    font-size: $font-size-large;
    font-weight: bold;
    text-align: center;
  }

  .submission__table {
    width: calc(100% - 2rem);
    background-color: $color-grey-lightest;
    border-radius: 0.5rem;
    margin: 1rem;
    th {
      background-color: $color-grey-mid;
      padding: 0.5rem;
      text-align: left;
    }

    tr {
      &:nth-child(even) {
        background-color: $color-grey-lightest-alt;
      }
    }
  }

  .submission__no-changes {
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: $font-size-large;
  }

  .submission__confirmation-text {
    padding: 1rem;
    font-size: $font-size-large;
    font-weight: bold;
    text-align: center;
    color: $color-red-darkest;
  }

  .submission__button-row {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;

    .submission__submit-button-final {
      background-color: $color-red-dark;
    }
  }

  .submission__result {
    padding: 1rem;
    font-size: $font-size-large;
    font-weight: bold;
    text-align: center;

    .submission__result-row {
      &--success {
        color: $color-green-dark;
      }
      &--failure {
        color: $color-red-dark;
      }
      &--link {
        text-decoration: underline;
        color: $color-bg-primary;
        cursor: pointer;
        &:hover {
          color: $color-bg-primary-highlight;
        }
      }
    }
  }
}
