.slider-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  
  .slider-label {
    margin-right: 1rem;
  }

  .slider-core {
    display: flex;
    align-items: center;

    width: 100%;
    height: 3rem;
  }

  .slider-segments {
    display: flex;
    flex-flow: row nowrap;    
    align-items: center;    
    flex-grow: 1;

    height: 100%;

    cursor: pointer;
    
    .slider-segment {
      position: relative;

      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;

      .slider-pointer {
        position: absolute;
    
        top: .5rem;
        left: calc(50% - .5rem);
        width: 1rem;
        height: 2rem;
        background-color: $color-grey-lightest;
        border: 1px solid $color-grey-dark;
        border-radius: 3px;            
      }

      &:last-of-type {
        .slider-pointer {
          left: auto;
          right: 0;
        }
      }

      &:first-of-type {
        .slider-pointer {
          left: 0;
        }
      }

      .slider-bar {          
        background-color: $color-bg-primary;
        height: 5px;        
        width: 100%;
      }      
    }
    
  }
}
