.content__report-editor {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.report-editor__main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  flex-grow: 1;
}

.report-editor__header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  padding-left: 0.5rem;

  width: 100%;

  background-color: $color-grey-lighter;
}

.report-editor__body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  background-color: $color-grey-light;

  height: calc(100vh - 16rem);
  overflow-y: auto;
}

.report-editor__title-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  .report-editor__title-text {
    margin-left: 0.5rem;
    font-size: $font-size-x-large;
    color: $color-blue-dark;
    font-weight: bold;
  }

  .report-editor__menu-return {
    // position: absolute;
    // top: 0.5rem;
    // right: 0.5rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    margin: 0.5rem;

    color: $color-bg-primary;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: $color-bg-primary-highlight;
    }
  }
}

.report-editor__table-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  //width: calc(100% - 30rem);
  //height: calc(100vh - 80px);
  overflow: auto;

  margin: 1rem;

  .table-container__table {
    border-collapse: separate;
    border-spacing: 0;
    // border-right-style: solid;
    // border-right-width: thin;
    // border-right-color: $color-black;
    // border-bottom-style: solid;
    // border-bottom-width: thin;
    // border-bottom-color: $color-black;
    table-layout: fixed;
    color: $color-black;
    background-color: $color-white;
    line-height: 1.5;
    padding: 1rem;

    .table__header {
      .header__root-cell {
        //width: 4rem;
        box-shadow: 0px 0px 0px 0.5px $color-black inset;

        background-color: $color-grey-lightest;
        //position: -webkit-sticky;
        //position: sticky;
        position: relative;
        top: 0;
        left: 0;
        //z-index: 2;
      }

      .header__column-cell {
        text-align: center;

        background-color: $color-grey-lightest;
        position: -webkit-sticky;
        position: sticky;
        line-height: 1.5;
        top: 0;
        //z-index: 1;

        border: 1px solid $color-grey-mid;
        border-left: 0;

        .column-cell__body {
          width: 100%;
        }

        .column-cell__insert,
        .column-cell__resize {
          position: absolute;
          top: 3px;
          height: 16px;
          width: 16px;
          z-index: 10;

          &--before {
            left: -8px;
          }

          &--after {
            right: -8px;
          }

          background-color: $color-grey-lightest;
          border-radius: 50%;
        }

        .column-cell__insert {
          color: $color-green-dark;

          &:hover {
            color: $color-green-mid-dark;
            cursor: pointer;
          }
        }

        .column-cell__resize {
          color: $color-black;

          &:hover {
            cursor: col-resize;
          }
        }
      }
    }

    .table__body {
      // .body__subtable-outer {
      //   background-color: $color-grey-light;
      //   border-radius: 0;
      // }

      .body__section-header-row {
        line-height: 2;
        z-index: 1;

        &:hover {
          cursor: pointer;

          .section-header-row__cell {
            background-color: $color-bg-primary-highlight;
            text-decoration: underline;
          }
        }

        .section-header-row__cell {
          color: $color-white;
          font-weight: bold;
          font-size: large;

          padding-left: 1rem;

          left: 0;
          &--first {
            position: sticky;
            position: -webkit-sticky;
            overflow: visible;
            white-space: nowrap;
            border-top-left-radius: 0.5rem;
          }

          &--last {
            border-top-right-radius: 0.5rem;
          }

          background-color: $color-bg-primary;
        }

        .section-header-row__add-sub-report {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;

          .add-sub-report__button,
          .add-sub-report__section-picker {
            background-color: $color-grey-light;
            font-size: small;
            color: $color-black;
            border-radius: 3px;
          }

          .add-sub-report__button {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            position: relative;

            padding: 0 1rem;
            margin-right: 1rem;
            height: 2rem;
            //width: 10rem;

            transition: 0.2s all;

            &:hover {
              background-color: $color-grey-lighter;
            }
          }

          .add-sub-report__section-picker {
            position: absolute;
            top: 18px;
            left: 0;

            overflow: auto;
            min-width: 160px;
            padding: 5px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 5;

            .section-picker__item {
              padding: 0;
              line-height: 1.5;
              &:hover {
                background-color: $color-grey-lighter;
              }
            }
          }
        }
      }

      .body__blank-row {
        &--sub-table {
          background-color: $color-grey-light;
        }
      }

      .body__subsection-header-row {
        color: $color-black;
        font-weight: bold;

        //box-shadow: 0.5px 0 0 $color-black inset, 0 0.5px 0 $color-black inset, 0 -0.5px 0 $color-black inset;
        background-color: $color-grey-light;
        z-index: 1;
        .subsection-header-row__cell {
          padding-left: 1.7rem;
          position: -webkit-sticky;
          position: sticky;
          left: 0;
          overflow: visible;
          white-space: nowrap;

          font-size: medium;
        }
      }
      .body__row-header-cell {
        text-align: center;
        vertical-align: middle;
        overflow: visible;
        background-color: $color-grey-lightest;
        color: $color-black;
        font-style: normal;
        text-decoration: none;

        // position: -webkit-sticky;
        // position: sticky;
        position: relative;
        line-height: 1.5;
        left: 0;
        z-index: 1;

        border-left: 1px solid $color-grey-mid;

        .row-header-cell__insert,
        .row-header-cell__resize {
          position: absolute;
          left: -8px;
          height: 16px;
          width: 16px;
          z-index: 10;

          &--before {
            top: -8px;
          }

          &--after {
            bottom: -8px;
          }

          background-color: $color-grey-lightest;
          border-radius: 50%;
        }
      }

      .row-header-cell__insert {
        color: $color-green-dark;

        &:hover {
          color: $color-green-mid-dark;
          cursor: pointer;
        }
      }

      .row-header-cell__resize {
        color: $color-black;

        &:hover {
          cursor: row-resize;
        }
      }

      .body__section-cell {
        white-space: nowrap;
        padding: 0px 5px;

        &:not(&--popup-showing) {
          overflow: hidden;
        }

        &:last-of-type {
          border-right: 1px solid $color-grey-mid;
        }

        .section-cell__contents {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;

          &:hover {
            cursor: text;
          }

          .section-cell__field-name {
            &:hover {
              color: $color-blue-mid;
              font-weight: bold;

              cursor: grab;
            }
          }

          input {
            height: 100%;
            width: 100%;
          }

          .contents__popup-container {
            position: relative;

            .popup-container__open-button {
              display: flex;
              justify-content: center;
              color: $color-grey-mid;

              cursor: pointer;
              &:hover {
                color: $color-grey-light;
              }
            }

            .popup-container__popup {
              position: absolute;
              top: 20px;
              right: 2px;
              //width: 10rem;
              //height: 5rem;
              z-index: 10;

              background-color: $color-grey-lighter;
              border-radius: 0.5rem 0 0.5rem 0.5rem;
              padding: 5px;
              .popup__option {
                &:hover {
                  background-color: $color-grey-lightest;
                  cursor: pointer;
                }
                &--selected {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .body__row-header-cell,
      .body__section-cell {
        border-bottom: 1px solid $color-grey-mid;
      }
    }

    // .table__border-all {
    //   box-shadow: 0.5px 0 0 $color-black inset, 0 0.5px 0 $color-black inset, 0 -0.5px 0 $color-black inset,
    //     -0.5px 0 0 $color-black inset;
    // }

    // .table__border-bottom {
    //   box-shadow: 0 -0.5px 0 0 $color-black inset;
    // }

    // .table__border-bottom-left {
    //   box-shadow: 0.5px 0 0 $color-black inset, 0 -0.5px 0 0 $color-black inset;
    // }

    // .table__border-top-bottom {
    //   box-shadow: 0 0.5px 0 $color-black inset, 0 -0.5px 0 $color-black inset;
    // }

    // .table__border-top-bottom-left {
    //   box-shadow: 0.5px 0 0 $color-black inset, 0 0.5px 0 $color-black inset, 0 -0.5px 0 $color-black inset;
    // }

    // .table__border-left-right {
    //   box-shadow: -0.5px 0 0 $color-black inset, 0.5px 0 0 $color-black inset;
    // }

    // .table__border-top-left-right {
    //   box-shadow: -0.5px 0 0 $color-black inset, 0.5px 0 0 $color-black inset, 0 0.5px 0 $color-black inset;
    // }

    .table__object--selected {
      background-color: $color-bg-primary-lightest !important;
    }
  }
}

.report-editor__mail-merge-container {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  margin: 1rem;

  .mail-merge-container__properties {
    width: 100%;
    background-color: $color-grey-lighter;
    border-radius: 1rem;

    .properties__title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: start;
      padding: 1rem;
      background-color: $color-bg-primary;
      color: $color-white;
      font-size: small;
      width: 100%;
      border-radius: 1rem 1rem 0 0;
    }

    .properties__body {
      padding: 1rem;
      width: 100%;
    }
  }

  .mail-merge-container__editor {
    overflow: visible;
    width: 100%;
    position: relative; /* Ensure relative positioning */
  }

  .mail-merge-container__input {
    font-size: $font-size-medium;
    color: $color-black; //$color-text-primary;
    background-color: $color-white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 30rem;

    input {
      width: 100%;
      height: auto;
      font-size: $font-size-medium;
    }

    .select {
      padding-top: 0;
    }
  }

  .mail-merge-container__label {
    margin: 0.5rem 0rem;
    font-size: $font-size-small;
  }

  .mail-merge-container__object-modal {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    margin: 0;

    .object-modal__display-name {
      font-size: $font-size-small;
      padding-bottom: 0.25rem;
      width: 100%;
      margin: 0rem, 1rem;
    }

    .object-modal__display-value {
      font-size: $font-size-medium;
      width: 100%;
      border-radius: 0.5rem;
      background-color: $color-white;

      input,
      textarea {
        font-size: $font-size-medium;
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
      }
    }

    .object-modal__selection-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      width: 100%;
      padding: 1rem;
      font-size: $font-size-small;

      button {
        margin: 0 1rem;
      }
    }
  }

  .mail-merge-container__object {
    background-color: $color-grey-lighter;
    width: 100%;
    border-radius: 1rem;

    .object__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: 1rem;
      background-color: $color-bg-primary;
      color: $color-white;
      font-size: small;
      font-weight: bold;
      width: 100%;
      border-radius: 1rem 1rem 0 0;

      .object__title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: start;
        font-size: small;
        font-weight: bold;
      }

      .object__title-input {
        background-color: $color-white;
        height: fit-content;
        border-radius: 0.5rem;
        padding: 0 1rem;
        width: 20rem;
      }
    }

    .object__body {
      padding: 1rem;
      width: 100%;

      .object__item {
        width: 100%;
      }
    }
  }
}

.report-editor__pivot-container {
  display: inline-block;
  width: calc(100% - 30rem);
  height: calc(100vh - 80px);
  overflow: auto;

  .pivot-container__pivot-axes {
    display: grid;
    min-height: 100px;
    grid-gap: 5px;
    grid-template-columns: 200px 200px 1fr;
    grid-template-rows: 200px 200px 1fr;

    .pivot-axes__axis {
      height: 100%;

      .axis__label {
        font-weight: bold;
        height: 2rem;
      }

      .axis__fields-container {
        overflow: auto;
        border: solid 1px $color-black;
        height: calc(100% - 2rem);
        padding: 4px;

        .fields-container__field {
          display: grid;
          grid-template-columns: 1fr 20px;

          .field__label {
            grid-column: 1;
            grid-row: 1;
          }

          .field__delete {
            grid-column: 2;
            grid-row: 1;
            &:hover {
              color: $color-red;
            }
          }
        }
      }
    }

    .pivot-axes__column-axis {
      overflow: auto;
      grid-column: 2;
      grid-row: 1;
    }

    .pivot-axes__row-axis {
      overflow: auto;
      grid-column: 1;
      grid-row: 2;
    }

    .pivot-axes__data-axis {
      overflow: auto;
      grid-column: 2;
      grid-row: 2;
    }
  }
}

.report-editor__field-list {
  display: inline-block;
  width: 30rem;
  background-color: $color-grey-lightest;
  border-style: none;

  position: relative;

  .field-list__filter-input {
    margin: 1rem;
    background-color: $color-white;
    width: calc(100% - 2rem);
    height: 3rem;
    padding-left: 0.5rem;
    border: 1px solid $color-bg-primary;
    border-radius: 0.5rem;
  }

  .filter-input__icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    color: $color-grey-mid;
    font-size: $font-size-x-large;
  }

  .field-list__groups {
    height: calc(100% - 5rem);
    overflow: auto;

    .groups__group-header {
      background-color: $color-bg-primary;
      color: $color-white;
      border-radius: 0.3rem;
      padding: 2px;
      margin: 1px 4px;
      float: left;
      width: calc(100% - 8px);
    }

    .groups__group-header-filtered {
      font-style: italic;
      font-weight: bold;
    }

    .groups__item {
      border: 0.5px dashed $color-grey-mid;
      border-radius: 0.3rem;
      background-color: $color-white;
      padding: 2px;
      margin: 1px 4px;
      cursor: grab;
      float: left;
      width: calc(100% - 8px);

      .matching-chars {
        font-weight: bold;
        color: $color-green-dark;
      }
    }
  }
}

.content__report-editor {
  .report-editor__field-list {
    height: calc(100vh - 8rem);
  }
}

.report-editor__output-container {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  color: $color-black;
  background-color: $color-white;
}

.output-container__table {
  border-collapse: collapse;
  table-layout: fixed;

  .table__cell {
    padding: 2px;
    overflow-wrap: break-word;
  }
}

.output-container__pivot-table {
  border-collapse: collapse;
  background-color: $color-white;
  border: 1px solid black;
  margin: 5px;
  width: auto;

  .pivot-table__root {
    position: -webkit-sticky;
    position: sticky;
    background-color: $color-bg-primary-lighter;
    top: 0px;
    left: 0px;
    z-index: 3;
  }

  .pivot-table__header {
    background-color: $color-bg-primary-lighter;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  .pivot-table__header-cell {
    padding: 2px;
    color: $color-black;
    background-color: $color-bg-primary-lighter;
    border: 1px solid black;
    overflow-wrap: break-word;
    text-align: center;
  }

  .pivot-table__label-cell {
    padding: 2px;
    background-color: $color-bg-primary-lighter;
    border: 1px solid black;
    overflow-wrap: break-word;

    padding-left: 1rem;
    position: -webkit-sticky;
    position: sticky;
    // top: 0;
    left: 0;
    overflow: visible;
    white-space: nowrap;
    z-index: 1;
  }

  .pivot-table__data-cell {
    padding: 2px;
    border: 1px solid black;
    overflow-wrap: break-word;
    text-align: right;
  }

  .pivot-table__total-cell {
    padding: 2px;
    color: $color-black;
    font-weight: bold;
    text-align: right;
    border: 1px solid black;
    overflow-wrap: break-word;
  }
}

.report-editor__toolbar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 0;
  //  height: 26px;

  .toolbar__section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    margin-left: 0.5rem;
    border: 2px solid $color-bg-primary;
    border-radius: 3px;

    .section__title {
      background-color: $color-bg-primary;
      color: $color-white;
      font-size: small;
      width: 100%;
    }
    .section__content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .toolbar__button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 26px;
    height: 26px;
    background-color: $color-grey-lightest;
    color: $color-black;

    border: none;
    text-align: center;
    vertical-align: middle;

    cursor: pointer;

    line-height: 26px;
    .button__icon {
      vertical-align: middle;
      line-height: inherit !important;

      &--red {
        color: $color-red-dark;
      }
    }
    .button__execute {
      color: $color-red;
    }
    &:hover {
      background-color: $color-bg-primary-lighter;
    }
  }
  .toolbar__input {
    display: inline-block;
    background-color: $color-grey-lightest;
    padding: 0px 4px;
    height: 26px;
    line-height: 26px;
    border: none;
    text-align: center;
    vertical-align: middle;
  }
  .toolbar__separator {
    display: inline-block;
    width: 4px;
  }
  .toolbar__object--selected {
    background-color: $color-grey-light !important;
    &:hover {
      background-color: $color-bg-primary-lighter !important;
    }
  }
  .toolbar__border-picker {
    position: absolute;
    background-color: $color-grey-lightest;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;

    .border-picker__grid {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 8px;
      padding: 8px;

      .grid__item {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 0px;
        width: 16px;
        height: 16px;

        .item__quarter {
          display: inline-block;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  .toolbar__color-picker {
    position: absolute;
    background-color: $color-grey-lightest;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    .color-picker__table {
      border-collapse: collapse;
    }
  }
  .toolbar__font-picker {
    display: inline-block;
    vertical-align: middle;
    height: 26px;
    background-color: #efefef;
    .font-picker__select {
      font-size: 14px;
      height: 22px;
      vertical-align: middle;
      margin: 0px 4px 3px 4px;
    }
  }
  .toolbar__number-picker {
    position: absolute;
    background-color: $color-grey-lightest;
    max-height: 300px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    padding: 2px 10px;
    text-align: right;
  }
  .toolbar__number-picker-input {
    display: flex;
    flex-flow: row nowrap;
    background-color: $color-grey-lightest;
    padding: 0px 4px;
    height: 26px;
    border: none;
    text-align: center;
    vertical-align: middle;

    .number-picker-input__input {
      vertical-align: middle;
      text-align: right;
      height: 22px;
      width: 64px;
      font-size: 14px;
      margin: 0px 4px 3px 4px;
    }
  }
  .toolbar__ownership {
    position: absolute;
    background-color: $color-grey-lightest;
    min-width: 160px;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;

    .ownership__button {
      margin: 0.5rem;
    }
  }
  .toolbar__menu {
    position: absolute;
    background-color: $color-grey-lightest;
    min-width: 160px;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    .menu__item {
      &:hover {
        background-color: $color-bg-primary-lighter !important;
      }
    }
  }

  .add-sub-report__section-picker {
    position: absolute;
    background-color: $color-grey-lightest;
    overflow: auto;
    min-width: 160px;
    padding: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;

    .section-picker__item {
      &:hover {
        background-color: $color-bg-primary-lighter !important;
      }
    }
  }

  .sort-order {
    &__label {
      margin-right: 1rem;
      width: 7rem;
    }

    &__select {
      margin-right: 0.5rem;
      width: 15rem;
    }

    &__checkbox {
      margin-right: 1rem;
      width: 7rem;
    }

    &__icon {
      margin-right: 1rem;
      width: 3rem;
      font-size: $font-size-x-large;

      &--button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        border: 1px solid $color-grey-mid;
        border-radius: 0.5rem;
        background-color: $color-grey-lighter;

        &:hover {
          cursor: pointer;
          background-color: $color-grey-lightest;
        }
      }
    }

    &__icon-button {
      width: 3rem;
      font-size: $font-size-x-large;
    }
  }
  // .toolbar__sort-order {
  //   position: absolute;
  //   background-color: $color-white !important;
  //   min-width: 220px;
  //   // min-height: 200px;
  //   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  //   z-index: 5;

  //   .sort-order__key {
  //     margin: 2px;
  //     padding: 2px;
  //     background-color: $color-grey-lighter;
  //     margin-bottom: 5px;

  //     .key__checkbox {
  //       padding: 0.25rem;
  //     }

  //     .selector__header {
  //       display: grid;
  //       grid-template-columns: 1fr auto;
  //       align-items: center;
  //       background-color: $color-bg-primary;
  //       color: $color-white;

  //       .header__title {
  //         font-size: 1.5rem;
  //         font-weight: bold;
  //         color: $color-black;
  //         padding: 0.5rem;
  //       }

  //       .header__close-x {
  //         color: $color-white;
  //         cursor: pointer;
  //         &:hover {
  //           color: $color-black;
  //         }
  //       }
  //     }

  //     .selector__value {
  //       display: block;
  //       background-color: $color-grey-lighter;
  //       padding: 0.25rem 0.5rem 0.75rem 0.5rem;
  //       min-width: 15rem;
  //     }
  //   }
  //}
}

.basic-report__content {
  .field-list__groups {
    height: 26rem;
  }
}
