.search-panel__simple-search {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  background-color: $color-bg-primary;

  //padding-right: 9rem;
  margin: 0 0.8rem;

  border: 2px $color-bg-primary solid;

  border-radius: 0.75rem;

  &--saved-search-attached {
    border-radius: 1rem 1rem 0 0;
  }

  width: 100%;

  position: relative;
  &--read-only {
    border: 0;
  }
}

// Search Type Dropdown

.simple-search__search-type-dropdown {
  position: relative;
  flex: 0 0 auto;
  background-color: $color-bg-button;

  border-radius: 0.5rem 0 0 0.5rem;

  height: 100%;

  margin-right: 2px;
  padding: 0 0.7rem;

  display: flex;
  justify-content: center;
  align-items: center;

  //color: $color-grey-darkest;
  color: $color-white;

  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    //background-color: $color-grey-lightest;
    background-color: $color-bg-button-highlight;
  }

  .search-type-dropdown__popup {
    position: absolute;
    top: 3.8rem; // calc(50% - 15rem);
    left: -2px;
    background-color: $color-bg-primary;
    padding: 1rem;
    min-width: 20rem;
    z-index: 900;
  }
}

// filters
.simple-search__filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  flex: 0 0 auto;
  flex-grow: 1;

  max-width: 85%;

  background-color: $color-white;

  .simple-search__expand-all-filters {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    height: 3rem;
    width: 2.5rem;
    margin: 4px 2px;

    font-size: 2rem;

    background-color: $color-bg-primary;
    color: $color-white;

    border-radius: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: $color-bg-primary-faded;
    }
  }

  .simple-search-filter__container {
    display: flex;
    flex-flow: column nowrap;

    position: relative;

    margin: 4px 2px; // Work this right

    .simple-search-filter__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      border-radius: 0.3rem 0.3rem 0 0;

      padding: 1px 5px;
      //height: 2.5rem;

      background-color: $color-bg-primary;

      font-size: $font-size-small;
      color: $color-white;

      &--matter-type {
        background-color: $color-yellow-dark;
      }
      &---read-only {
        background-color: $color-yellow-dark;
      }
    }

    .simple-search-filter__body {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      padding: 1px 5px;

      border-radius: 0 0 0.3rem 0.3rem; // 0.5rem 0 0;
      background-color: $color-grey-lighter;
      color: $color-grey-darkest;
      font-size: $font-size-small;

      cursor: pointer;
      &--read-only {
        cursor: default;
      }
    }
  }
}

.simple-search__filters-new {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 0;
  min-height: 3.3rem;
  gap: 3px;

  max-width: 70%;

  background-color: $color-bg-primary;

  .filters-new__pastille {
    display: flex;
    align-items: center;
    flex: 1 1 auto; /* Allows resizing */
    min-height: 20px; /* Minimum height when wrapped */

    padding: 2px 5px;

    background-color: $color-grey-light;
    border-radius: 0.5rem;

    font-weight: bold;

    transition: all 0.2s;

    .pastille__field-name {
      font-weight: normal;
    }

    .pastille__field-values {
      font-weight: bold;
    }

    &:hover {
      background-color: $color-grey-lighter;
      cursor: pointer;
    }
  }
}

.simple-search-filter__clear-all {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  border-radius: 0.5rem;
  height: 3.3rem;
  width: 2rem;
  margin: 4px 2px;

  background-color: $color-bg-primary;
  color: $color-white;

  &:hover {
    cursor: pointer;
    background-color: $color-bg-primary-faded;
  }
}

// Input Container
.simple-search__input-container {
  position: relative;

  display: flex;
  align-items: center;

  flex-grow: 1;

  min-height: 3.3rem;
  min-width: 17rem;
  z-index: 5;

  border: 1px solid $color-grey-mid;
  border-radius: 0.5rem;

  background-color: $color-white;
  margin-left: 0.5rem;
}

.simple-search__input {
  width: 100%;
  padding-left: 1rem;

  height: auto;
}

// right hand elements

.simple-search__main-search-button {
  flex: 0 0 auto;
  height: 100%;

  button {
    height: 100%;
    border-radius: 0;

    &:hover {
      background-color: $color-bg-button-highlight;
    }
  }
}

.simple-search__actions {
  flex: 0 0 auto;
  // position: absolute;
  // right: 0;
}

// connected matters overrides
.add-connected__search-panel {
  .simple-search__filters {
    margin-left: 1rem;
  }

  .simple-search__main-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    button {
      border-radius: 0 0.5rem 0.5rem 0;
    }
  }
}
