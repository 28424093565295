// SASS Mixins (similar to functions) - used in other SASS files to reduce code duplication

// MEDIA QUERY MANAGER
/*
0-600px:        Phone
600-900px:      Tablet Portrait
900-1200px:     Tablet Landscape
[1200-1800px]   Default
1800px+:        Large Desktop

$breakpoint argument choices:
- phone
- tab-port
- tab-land
- big-desktop
1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } // 600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 64em) {
      @content;
    } // 1024px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 76em) {
      @content;
    } // 1216px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } // 1800px
  }
}

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

@mixin popup-shadow() {
  box-shadow: 5px 5px 5px 0 $color-black-transparent;
}

@mixin error-box() {
  font-weight: bold;
  color: $color-red-darker;
  background-color: $color-red-lightest;
  border: 1px solid $color-red-darker;
}

@mixin full-screen-popup() {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100vw - 1rem);
  height: calc(100vh - 1rem);
  z-index: 900;

  background-color: $color-grey-lightest;
  border: 5px solid $color-bg-primary;
  border-radius: 1rem;

  @include popup-shadow;
}
