.data-panel__form {
  display: flex;
  flex-flow: row wrap;

  background-color: $color-white;

  padding: 1rem 1rem 1rem 0;

  border-radius: 0 0 1rem 1rem;

  .editable {
    transition: 0.2s all;
    &:hover {
      background-color: $color-grey-lighter;
    }
  }

  .data-panel__field {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    position: relative; // For validation symbol

    border-bottom: $color-bg-primary 1px solid;

    &:focus-within {
      border: $color-green-mid-dark 1px solid;
      background-color: $color-white;
    }

    border-radius: 0.5rem 0.5rem 0 0;

    flex-grow: 1;

    min-height: 4rem;
    margin: 0 0 1rem 1rem;
    padding: 2px;
    background-color: $color-grey-lighter;
    color: $color-text-secondary;

    &--boolean {
      justify-content: center;
      align-items: center;
      border-bottom: 0;
    }

    &--success {
      background-color: $color-green-lightest;
      color: $color-green-dark;
      border-color: $color-green-dark;
    }

    &--editing {
      background-color: $color-yellow;
      color: $color-yellow-dark;
      border-color: $color-yellow-darkest;
    }

    &--error {
      background-color: $color-red-lightest;
      color: $color-red-darker;
      border-color: $color-red-darker;
    }

    .field__display-name {
      margin-left: 0.5rem;
      font-size: $font-size-small;
    }

    .field__display-value {
      font-size: $font-size-medium;
      color: $color-black; //$color-text-primary;
      padding: 0 0.5rem 0 0.5rem;

      width: 100%;
      input,
      textarea {
        width: 100%;
        height: auto;
        //padding-left: 0.5rem;

        background-color: transparent;
        //color: $color-text-primary;

        font-size: $font-size-medium;

        &:focus {
          background-color: $color-white;
          //padding-left: 0.5rem;
        }
      }

      .select {
        padding-top: 0;
      }
    }

    .field__validation-symbol {
      position: absolute;
      top: 1.2rem;
      right: 2.5rem;

      font-size: $font-size-x-large;

      &--success {
        color: $color-green-dark;
      }
      &--error {
        color: $color-red-dark;
      }
    }
  }

  .data-panel__control-field {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .data-panel__field,
  .data-panel__control-field {
    &--100 {
      width: 100%;
    }

    &--66 {
      width: 65%;
    }

    &--50 {
      width: 49%;
    }

    &--33 {
      width: 32%;
    }
  }
}

.field__validation-symbol {
  position: absolute;
  top: 1.2rem;
  right: 2.5rem;

  font-size: $font-size-x-large;

  &--success {
    color: $color-green-dark;
  }
  &--error {
    color: $color-red-dark;
  }
}
