.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: violet;

  padding: 1rem;
  margin: 1rem;

  .child-element {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    margin: 0.5rem;
    padding: 0.5rem;

    &--selected {
      background-color: yellow;
    }
  }
}
