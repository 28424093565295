// styles for the delete button and confirmation popup controls
.delete__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  color: $color-red-dark;

  font-size: $font-size-x-large;
  transition: 0.2s all;

  &:hover {
    cursor: pointer;
    color: $color-red;
  }
}

.delete__confirmation-popup {
  @include popup-shadow;
  width: 35rem;
  background-color: $color-grey-light;

  border-radius: 0.5rem;

  color: $color-grey-dark;

  &--force-top {
    position: absolute;
    top: 0;
  }

  .popup-header {
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem;
  }

  .delete__confirmation-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    .delete__confirmation-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      width: 100%;
      padding: 1rem;
      font-size: $font-size-small;

      button {
        margin: 0 1rem;
      }

      button.delete__confirmation-confirm {
        background-color: $color-red-dark;

        &:hover {
          background-color: $color-red;
        }
      }
    }
  }
}
