.batch-create {
  position: relative;
  width: 100%;
  padding: 1rem;
  background-color: $color-grey-lightest;

  .record__data-panel-list {
    min-height: calc(100vh - 17rem);
    .data-panel-list__outer {
      .data-panel-list__data-panel {
        border: 1px solid $color-bg-primary;
        border-radius: 1rem;
        //background-color: $color-white;
        .data-panel__header-lhs {
          .data-panel__title {
            font-size: $font-size-large;
            font-weight: bold;
          }
        }
      }
    }
  }

  .batch-create__bold-one-liner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    font-size: $font-size-large;
    font-weight: bold;
  }
}

.batch-create__section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.section__decision {
  font-size: medium;
  font-weight: bold;
}

.section__manual-addition {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  padding: 0.5rem;

  background-color: $color-grey-lightest;
  border-radius: 0.5rem;

  .manual-addition__country-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .manual-addition__country-label {
      margin-right: 0.5rem;
    }

    .manual-addition__country-select {
      background-color: $color-white;
      border-radius: 2px;
      padding: 0 2px;
      width: 20rem;
    }
  }

  .manual-addition__table {
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: $color-grey-light;
    border-radius: 0.5rem;

    .table__cell-input {
      border-radius: 3px;
      padding-left: 1rem;
    }
    .react-datepicker-contained,
    .table__cell-select {
      border-radius: 3px;
      padding-left: 1rem;
      background-color: $color-white;
    }
  }
}

.batch-create__search {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .search__table {
    margin: 1rem 0;
    //max-width: 65vw;
    // height: 50vh;
    // overflow-y: scroll;
  }

  .search-results__footer {
    background-color: transparent;
  }
}

.search__bar {
  background-color: $color-grey-lightest;
  border-radius: 0.5rem;

  .simple-search__filters {
    border-radius: 0.5rem;
  }
}

.batch-create__core-details {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  padding: 0.5rem;

  background-color: $color-grey-lightest;
  border-radius: 0.5rem;

  .core-details__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .core-details__label {
      margin-right: 0.5rem;
    }

    .core-details__input {
      background-color: $color-white;
      border-radius: 2px;
      padding: 0 2px;

      input {
        width: 30rem;
      }
    }
  }
}

.section__jurisdiction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  padding: 0.5rem;

  background-color: $color-grey-lightest;
  border-radius: 0.5rem;

  .jurisdiction__countries-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .jurisdiction__countries-label {
      margin-right: 0.5rem;
      font-weight: bold;
    }

    .jurisdiction__countries-select {
      background-color: $color-white;
      border-radius: 2px;
      padding: 0 2px;
      min-width: 40rem;
    }
  }

  .jurisdiction__table {
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: $color-grey-lighter;
    border-radius: 0.5rem;

    tr:nth-child(odd) {
      background-color: $color-grey-lightest;
    }

    tr:nth-child(even) {
      background-color: $color-grey-lighter;
    }

    th {
      background-color: $color-grey-light;
    }

    td {
      text-align: center;

      .switch-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.section__summary {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  padding: 0.5rem;

  background-color: $color-grey-lightest;
  border-radius: 0.5rem;
}

.table__inner {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: $color-grey-light;
  border-radius: 0.5rem;

  /* Alternating row colors */
  tr:nth-child(odd) {
    background-color: $color-grey-lightest;
  }
  tr:nth-child(even) {
    background-color: $color-grey-lighter;
  }

  /* Sticky table header */
  th {
    background-color: $color-grey-mid;
    height: 3rem;
    padding: 0 1rem;
    position: sticky;
    top: 0;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $color-grey-dark;
    }
  }

  td {
    text-align: center;
    height: 3rem;
    padding: 0 1rem;

    /* Additional styling for checkbox container */
    .checkbox-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
