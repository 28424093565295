.security {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin: 0;

  .security__breadcrumbs {
    width: 100%;
    padding: 0.5rem;
    padding-left: 5rem;

    background-color: $color-grey-light;
  }

  .security__header {
    width: 100%;
    padding: 1rem;
    padding-left: 5rem;

    background-color: $color-grey-lighter;

    font-size: $font-size-x-large;
    font-weight: bold;
    color: $color-blue-dark;
  }

  .security__body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 1rem;
    padding-left: 5rem;

    // background-color: $color-grey-lightest;

    // width: 100%;
    // height: calc(100vh - 9rem);
  }

  .security__table-container {
    margin-bottom: 1rem;
    min-width: 30rem;
    border: 2px solid $color-bg-primary;
    border-radius: 0 0.75rem 0.75rem 0.75rem;
  }

  .security__title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .security__show-hidden-users {
    cursor: pointer;
    color: $color-blue-dark;

    //padding: 2rem 0 0 0;
    margin-left: 3rem;

    &:hover {
      color: $color-blue-light;
    }
  }

  //.security__field-filter-panel {
  //    margin-right: 0.5rem;

  .search-panel__simple-search {
    width: 40rem;
    margin: 0.5rem;
    border-radius: 0.5rem;

    .simple-search__filters {
      width: 40rem;
      max-width: 100%;
      border-radius: 5px;
    }

    .simple-search__input-container {
      width: 26rem;
    }

    // .simple-search__main-search-button {

    // }
  }
  //}

  .security__section {
    background-color: $color-grey-lighter;
    border-radius: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .security__section-title {
    max-width: 90%;
    font-size: $font-size-large;
    font-weight: bold;
    background-color: $color-bg-primary;
    //margin-bottom: 0.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.5rem 1rem;
    color: $color-white;
    margin-top: 0.5rem;
  }

  .security__section-body {
    padding: 0.5rem;
    .security__select-container {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      .select {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
    .select {
      padding-left: 1rem;
      background-color: $color-white;
      height: 3rem;
    }
    td {
      padding: 0 0.5rem 0.5rem 0;
    }
  }

  .security__table {
    border-spacing: 1px;
    border-collapse: separate;

    &__header {
    }

    &__header-row {
    }

    &__header-row-cell {
      position: sticky;
      top: 0;
      z-index: 1;

      text-align: left;

      font-weight: bold;
      background-color: $color-grey-lighter;
      color: $color-grey-darkest;
      padding: 0.5rem 2rem 0.5rem 0.5rem;

      &:first-of-type {
        border-radius: 0.5rem 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 0.5rem 0 0;
      }
      &--clickable {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__body {
      &__no-data {
        font-size: medium;
        padding: 1rem 0 0 1rem;
        margin: 1rem;
      }
    }

    &__body-row-cell {
      padding: 0.5rem;
      word-break: break-word;
      padding-right: 2rem;
      &--edit {
        justify-content: center;
      }
      &--disabled {
        opacity: 50%;
      }
      transition: 0.2s all;
      &--red {
        background-color: $color-red-lightest;
      }

      background-color: $color-grey-lightest;
    }

    &__body-row {
      &:last-of-type {
        .security__table__body-row-cell {
          &:first-of-type {
            border-radius: 0 0 0 0.5rem;
          }
          &:last-of-type {
            border-radius: 0 0 0.5rem 0;
          }
        }
      }
    }
  }
  .security__title-container {
    display: flex;
    align-items: center;
    padding-top: 1rem;
  }
  .security__title {
    margin-right: 1rem;
  }
}

.password-reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 5rem);
  background-color: $color-grey-lighter;

  .password-reset {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    background-color: $color-white;
    border: 2px solid $color-bg-primary;
    border-radius: 1rem;
    padding: 1rem;

    width: 50%;

    .password-reset__title {
      font-size: $font-size-x-large;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .password-reset__label {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
    .password-reset__input {
      padding: 2px;
      margin-bottom: 1rem;

      border: 1px solid $color-bg-primary;
      border-radius: 0.5rem;

      input {
        padding-left: 1rem;
        font-weight: bold;
        font-size: x-large;
      }
    }

    button {
      font-size: $font-size-large;
    }

    .password-reset__error {
      color: $color-red-dark;
      font-weight: bold;
      margin: 1rem;
    }

    .password-reset__success {
      color: $color-green-dark;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
}
.security__access-rights__categories,
.security__access-rights__companies__link-type {
  background-color: $color-grey-lighter;
  border-radius: 0.5rem;
  padding: 0.5rem;

  margin-bottom: 0.5rem;

  &--title {
    font-weight: bold;
    font-size: $font-size-large;
  }

  th {
    font-weight: bold;
    font-size: $font-size-large;
  }

  // td {
  //   padding-left: 1rem;
  //   width: 100%;
  // }
}

.security__company-link-access {
  margin-bottom: 0.5rem;
  .company-link-access__company {
    font-weight: bold;
  }

  .company-link-access__add-company {
    margin-bottom: 0.5rem;
    margin-top: -1rem;
  }

  .company-link-access__link-type {
    border-radius: 3px;
    padding: 2px 5px;
    font-weight: bold;
    font-size: $font-size-large;
    background-color: $color-grey-lighter;
  }
}
