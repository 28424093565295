.data-panel__table {
  display: flex;
  flex-flow: column nowrap;

  &--100 {
    width: 100%;
  }

  background-color: $color-white; //$color-grey-lighter;
  border-radius: 0 0 1rem 1rem;
  padding: 0.5rem;

  table {
    border-spacing: 0;
  }

  .data-panel__action-bar {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;

    margin-bottom: 0.2rem;
    padding: 0.5rem;

    .data-panel__table-add-button {
      display: flex;
      align-items: center;
      color: $color-white;
      background-color: $color-bg-primary;
      padding: 3px 10px;
      border-radius: 0.3rem;
      &:hover {
        background-color: $color-bg-primary-faded;
      }
    }

    .action-bar__switch {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      min-width: 20rem;
    }
  }

  .data-panel__validation {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;

    border-radius: 0.5rem;
    margin-bottom: 0.5rem;

    @include error-box;
  }

  .data-panel__table-group {
    .table-group__header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      font-weight: bold;

      background-color: $color-bg-primary-lighter;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-bottom: 0.5rem;

      cursor: pointer;

      &:hover {
        background-color: $color-bg-primary-lightest;
      }

      &--expanded {
        border-radius: 0.5rem 0.5rem 0 0;
        margin-bottom: 0;
      }
    }

    .data-panel__table {
      padding: 0;
      border-radius: 0;
      .data-panel__table-scrollable-container {
        .data-panel__table-inner {
          .data-panel__table-row {
            .data-panel__table-header {
              border-radius: 0;
            }
          }
        }
      }
    }

    .table-group__body {
      border: 1px solid $color-bg-primary-lighter;
    }
  }

  .data-panel__table-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;

    &--error {
      @include error-box;
    }
  }

  .data-panel__table-scrollable-container {
    width: 100%;

    border-radius: 1rem;

    &--document-emails {
      overflow-x: auto;
    }
  }

  .data-panel__table-inner {
    &--document-emails {
      width: 200%;
    }

    //background-color: $color-grey-lightest;

    .data-panel__table-row {
      width: 100%;

      transition: all 0.2s;

      background-color: $color-grey-lightest;

      &:nth-child(even) {
        background-color: $color-grey-lightest-alt;
      }

      // &:last-of-type {
      //   border-radius: 0 0 .5rem .5rem;
      // }

      &:hover {
        background-color: $color-bg-primary-lightest;
      }

      &--selected {
        background-color: $color-yellow-lightest !important;
      }

      // &--sub-table {
      //   background-color: $color-bg-primary-lighter;
      //   border: 0;
      // }

      .data-panel__table-header {
        font-size: $font-size-medium;
        font-weight: bold;
        background-color: $color-blue-lightest;

        padding: 0.5rem;
        padding-left: 4px;

        cursor: pointer;

        &--expander {
          width: 4rem;
        }

        &:first-of-type {
          border-radius: 0.5rem 0 0 0;
        }
        &:last-of-type {
          border-radius: 0 0.5rem 0 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      // .editable {
      //   background-color: $color-white;
      //   border-radius: 0.3rem;
      //   padding-left: 1rem;
      //   //margin: 2px 0;
      // }

      .data-panel__table-cell {
        position: relative; // For validation symbol
        //display: flex;
        //align-items: center;
        min-height: 5rem;
        font-size: $font-size-medium;
        //margin: 4px; Doesn't have any effect
        padding: 4px 0 4px 4px;
        vertical-align: center;

        word-break: break-word;

        .table-cell__field-container {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          //align-items: flex-start;

          border-radius: 0.3rem;
          &--editable {
            background-color: $color-white;
            //padding-left: 1rem;
          }
          min-height: 3rem;

          .react-datepicker-contained {
            margin-left: 0.5rem;
          }

          .field__validation-symbol {
            top: 1rem;
          }

          &:focus-within {
            border: 1px solid $color-green-dark;
          }

          &--success {
            background-color: $color-green-lightest !important;
            //color: $color-green-dark;
            border: 1px solid $color-green-dark;

            input {
              background-color: $color-green-lightest;
            }
          }

          &--error {
            border: 1px solid $color-red-dark;
          }

          &--has-long-text {
            //padding-left: 0.5rem;
            background-color: $color-white;

            .field__input {
              .elem-to-focus {
                padding-left: 0.5rem;
              }
            }
          }
          &--select {
            background-color: $color-white;
            border-radius: 0.3rem;
            padding-left: 1rem;
            margin: 0 0.5rem;
          }
        }

        &--action {
          background-color: transparent;
        }

        &--action-double {
          background-color: transparent;
        }

        &--expander {
          background-color: transparent;
          font-size: $font-size-large;
        }

        &--error {
          border: 1px solid $color-red-dark;
        }

        &--readonly {
          background-color: transparent;
          cursor: default;
        }

        //.field__display-value {
        // select {
        //   background-color: $color-white;
        //   border-radius: 0.3rem;
        //   padding-left: 1rem;
        //}

        .data-panel__sub-table-contacts {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          width: 100%;
          font-size: $font-size-large;

          .flex-row-center:hover {
            transition: all 0.2s;
            color: $color-grey-mid;
            cursor: pointer;
          }

          .Tooltip-Wrapper {
            padding-right: 2rem;
          }
        }

        .select {
          //padding-bottom: 0.5rem;
          //background-color: $color-white;
        }

        input {
          width: 100%;
          padding-left: 0.5rem;
          color: $color-grey-dark;
        }

        .field__input {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          min-height: 2rem;
          width: 100%;
          //height: 3rem;

          .elem-to-focus {
          }

          textarea {
            width: 100%;
            height: auto;
            width: 100%;
            //height: 8rem;
            color: $color-grey-dark;
            font-size: $font-size-medium;
            padding: 2px 5px;
            border: 1px $color-grey-light solid;
          }
        }

        .table-cell__image {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          img {
            max-width: 10rem;
            max-height: 3rem;
          }
        }

        .table-cell__filename-with-icon {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          color: $color-bg-primary;
          font-weight: bold;
          text-decoration: underline;

          &:hover {
            color: $color-bg-primary-faded;
          }

          img {
            margin-right: 2px;
          }
        }

        .react-datepicker__input-container {
          input {
            margin-left: 0;
            padding-left: 0;
          }
        }

        .object-list {
          width: 100%;

          &:hover {
            color: $color-grey-darkest;
            text-decoration: underline;
            cursor: pointer;
          }

          &--error {
            @include error-box;
            border-radius: 4px;
            padding: 1px 5px;
            margin: 1px 5px;
            width: auto;

            &:hover {
              color: $color-red-darker;
              text-decoration: none;
              background-color: $color-red-lighter;
            }
          }
        }
      }

      .data-panel__save-row {
        color: $color-blue;
        font-size: $font-size-x-large;
        background-color: transparent;
      }

      .data-panel__table-header,
      .data-panel__table-cell {
        flex-grow: 1;

        &--100 {
          width: 100%;
        }
        &--90 {
          width: 90%;
        }
        &--80 {
          width: 80%;
        }
        &--70 {
          width: 70%;
        }
        &--66 {
          width: 66%;
        }
        &--60 {
          width: 60%;
        }
        &--50 {
          width: 50%;
        }
        &--40 {
          width: 40%;
        }
        &--35 {
          width: 35%;
        }
        &--33 {
          width: 33%;
        }
        &--30 {
          width: 30%;
        }
        &--25 {
          width: 25%;
        }
        &--20 {
          width: 20%;
        }
        &--15 {
          width: 15%;
        }
        &--10 {
          width: 10%;
        }
        &--5 {
          width: 5%;
        }
        &--action {
          flex-grow: 0;
          width: 0.1%; // Fix for production build to prevent it setting this to "0" instead of "0%" and resultant table from collapsing to left
          min-width: 3rem;
        }
        &--action-double {
          width: 0.1%;
          min-width: 6rem;
        }
      }
    }
  }

  &--sub-table {
    width: 80%;
    margin: 0 0 1rem 2.3rem;

    padding: 0.5rem;
    background-color: $color-bg-primary-lighter;
    border: 0;

    box-shadow: inset 0 3px 3px 0 $color-black-transparent;

    .data-panel__action-bar {
      margin: 0;
      padding: 0;
    }

    .data-panel__table-scrollable-container {
      .data-panel__table-inner {
        background-color: $color-bg-primary-light;
        .data-panel__table-row {
          background-color: $color-bg-primary-lightest;

          &:nth-child(even) {
            background-color: $color-bg-primary-lightest-alt;
          }

          &:hover {
            background-color: $color-grey-lightest;
          }
          .data-panel__table-header {
            background-color: $color-bg-primary-lighter;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
}

// Annoying tweak for border-radius of table headers within saved searches
.saved-searches__body .data-panel__table .data-panel__table-inner .data-panel__table-row .data-panel__table-header {
  border-radius: 0;
}

.data-panel__table-row--adding {
  .table-cell__field-container {
    background-color: $color-yellow-lightest !important;
    border: 1px solid $color-yellow;

    input {
      background-color: $color-yellow-lightest !important;
    }
  }
}
