// styles for the share link popup controls
.link-copy-modal__popup {
  @include popup-shadow;
  width: fit-content;
  background-color: $color-grey-light;

  border-radius: 0.5rem;

  color: $color-grey-dark;

  &--force-top {
    position: absolute;
    top: 0;
  }

  .popup-header {
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem;
  }

  .link-copy-modal__body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: fit-content;

    .link-copy-modal__row {
      display: flex;

      justify-content: center;
      align-items: center;

      width: fit-content;
      padding: 1rem;
      font-size: $font-size-medium;

      button {
        margin: 0 1rem;
      }

      input {
        font-size: $font-size-large;
        border-radius: 0.5rem;
        border: 1px solid $color-grey-mid;
        min-width: 30rem;
        padding-left: 0.5rem;
      }

      .select {
        background-color: $color-white;
        border-radius: 0.5rem;
        padding: 0.5rem;

        input {
          border: 0;
          font-size: $font-size-medium;
        }
      }
    }
  }
}
