.breadcrumbs__container {
  display: flex;
  list-style-type: none;
  //padding: 2rem 0 0 5rem;
}

.breadcrumbs__list-container {
  display: flex;
}

.breadcrumbs__list-item {
  font-size: small;
  &--icon {
    display: flex;
    align-items: center;
    margin: 0 1rem 0 1rem;
  }
  &--text {
    font-weight: bold;
  }
  &:last-child {
    text-decoration: none;
    cursor: default;
  }
}
