.filters-new__popup {
  position: absolute;
  top: 3.8rem; // calc(50% - 15rem);
  left: -2px;

  display: flex;
  flex-flow: column nowrap;

  max-height: calc(100vh - 6rem);
  //width: calc(100% + 4px);
  z-index: 900;

  overflow: hidden;

  background-color: $color-bg-primary;
  @include popup-shadow;

  border-radius: 0 0 1rem 1rem;
  padding: 0.5rem;
  //margin: -0.5rem;

  .suggested-field__popup {
    width: 45rem;
  }
}

.popup__header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;

  background-color: $color-bg-primary;
  color: $color-white;

  .header__title {
    //color: $color-grey-darker;
    font-size: $font-size-large;
    font-weight: bold;
    font-size: $font-size-large;
    padding: 0.5rem;
  }

  .header__search-panel {
    padding: 0 0.5rem;

    input {
      border-radius: 0.5rem;
      margin-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
}

// This is the unique container for the existing filters version of the field list
// Compare with .suggested-field__popup-container for RHS popup on suggested field matches
.popup__body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  padding-top: 0.5rem;

  max-height: calc(100vh - 20rem);
  min-height: 0;

  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 0 0 0.5rem 0.5rem;
  background-color: $color-grey-light;

  .popup__filter {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    //height: 110rem;

    padding: 0.5rem 1rem;

    color: $color-bg-primary;

    background-color: $color-bg-primary;
    border-radius: 3px;
    margin: 3px;

    width: calc(100% - 6px);

    .filter__title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-weight: bold;
      color: $color-white;
    }

    .filter__body {
      margin-top: 1rem;
      width: 100%;
      background-color: $color-grey-lighter;
      border-radius: 3px;
    }

    .popup__cell {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      font-weight: bold;
      text-decoration: underline;

      &--15 {
        width: 15%;
      }

      &--30 {
        width: 30%;
      }

      &--70 {
        width: 70%;
      }
    }
  }
}
