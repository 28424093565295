.date-alert {
  position: relative;
}

.inline-alert {
  margin-right: 1rem;

  &--absolute {
    position: absolute;
    right: 2rem;
    margin-right: 0;
  }

  display: flex;
  align-items: center;

  font-size: $font-size-large;

  &--warning {
    color: $color-yellow;
  }

  &--error {
    color: $color-red;
  }
}
