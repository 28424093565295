// Styles that are used throughout all content pages

.content {
  flex-grow: 1;
  overflow-y: auto; // TEMP

  // DO NOT SET HEIGHT - AUTO/VARIES

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  .menu {
    padding: 1rem 1rem 0 1rem;

    .menu__btn {
      border-width: 1px 1px 0 1px;
    }
  }

  .error-line {
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
  }
}

.content__filter {
  color: $color-white;
  border: 0;
  border-radius: 0.5rem;
  height: 2rem;
  padding: 0 1rem;

  cursor: pointer;
  &:hover {
    background-color: $color-grey-mid;
  }
}
