// Styles relating to the app as a whole

body {
  margin: 0;
}

#root {
  position: relative;

  .app {
    //height: 100vh;
    display: flex;
    flex-flow: column nowrap;

    height: 100vh;
    //width: 100vw;
    //overflow-y: hidden;

    position: relative;

    // grid-template-columns: auto;
    // grid-template-rows: 4rem auto;

    // grid-template-areas: "area-header" "area-content";
  }

  .test-footer {
    flex-shrink: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    background-color: yellow;
    font-weight: bold;
    color: black;
  }

  .modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-modal-mask;
    z-index: 500;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
