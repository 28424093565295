.home {
  //padding: 1rem;
  background-color: $color-grey-lighter;
  width: 100%;
  height: 100%;

  .home__status-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    height: 3rem;

    .status-bar__item {
      font-size: $font-size-large;
      font-weight: bold;
      color: $color-text-secondary;
      margin: 0 1rem;
    }

    margin-bottom: 1rem;

    background-color: $color-grey-light;
  }

  .home__grid-paginator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    height: 3rem;
    margin-top: 0.5rem;

    .paginator__chevron {
      font-size: 3rem;

      &:not(&--disabled) {
        cursor: pointer;
        color: $color-blue-dark;

        &:hover {
          color: $color-blue;
        }
      }

      &--disabled {
        color: $color-blue-lighter;
      }
    }
  }

  .home__widgets {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin: 1rem;
    .home__widget-diary {
      height: calc(100vh - 11rem);
      .home__widget {
        height: 100%;
        .widget__body {
          height: 100%;
          .search-results__data-grid {
            margin-top: 1rem;
            margin-bottom: 3rem;
          }
        }
      }
    }

    .home__widgets-grid-container {
      .home__widgets-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, calc(50% - 0.5rem));
        column-gap: 1rem;
        row-gap: 1rem;
        height: calc(100vh - 11rem);
        overflow-y: auto;
        overflow-x: hidden;
        margin-right: 1rem;
      }
    }

    .home__widget {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: stretch;

      border: 2px solid $color-blue-lighter;
      border-radius: 1rem;

      background-color: $color-white;

      &--zoomed {
        display: block;
        position: absolute;
        width: calc(100vw - 2rem);
        height: calc(100vh - 11rem);
        z-index: 2;

        // .widget__body {
        //   height: 100% !important;
        // }
      }

      .widget__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        background-color: $color-blue-lighter;
        color: $color-grey-darker;
        padding: 0.5rem;
        border-radius: 0.5rem 0.5rem 0 0;

        font-size: $font-size-large;
        font-weight: bold;

        .widget__header-title {
          &:hover {
            cursor: pointer;
            color: $color-blue-mid;
          }
        }

        .widget__header-icons {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;

          .widget__header-icon {
            display: flex;
            align-items: center;

            color: $color-blue-mid;
            &:hover {
              cursor: pointer;
              color: $color-blue-light;
            }
          }
        }
      }

      .widget__body {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: stretch;

        padding: 1rem;

        height: calc(100% - 3rem);

        .search-results__data-grid {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
