.batch-import {
  position: relative;
  width: 100%;
  padding: 1rem;
  background-color: $color-grey-lightest;

  .simple-search__filters {
    max-width: 100%;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
  }
}

.batch-import__field-filter-panel {
  //margin: 2rem 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.batch-import__search-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: $color-grey-lightest;
  padding: 1rem 5rem;
  //margin: 1rem 10rem;
  border-radius: 1rem;
  align-items: center;
  width: 100%;

  &--search-only {
    height: 50vh;
    align-items: start;
    border: 0;
  }
}

.batch-import__search-results {
  &__header-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 5rem;
    width: 100%;
  }

  &__body-container {
    padding: 0 5rem;
    width: 100%;
  }

  &__footer-container {
    padding: 0 5rem;
    width: 100%;
  }
}

.batch-import__prepare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.batch-import__data-source {
  &__container {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
    position: relative;
    border: 1px solid $color-grey-mid;
    border-radius: 0.3rem;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    border-radius: 0.3rem 0.3rem 0 0;
    padding: 1px 5px;
    background-color: $color-yellow-dark;
    font-size: $font-size-small;
    color: $color-white;
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1px 5px;
    border-radius: 0 0 0.3rem 0.3rem;
    background-color: $color-white;
    color: $color-grey-darkest;
    font-size: $font-size-small;
  }
}

.batch-import__create-records {
  margin-top: 1rem;
}

// Modifiers to global classes
.batch-import {
  .data-panel__table-cell,
  .data-panel__table-header {
    text-align: center;
  }
  .data-panel__form {
    padding-left: 1rem;
  }
}
